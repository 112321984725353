@tailwind base;
@tailwind components;
@tailwind utilities;

.font-playfair {
  font-family: 'Playfair Display', serif;
}
.font-montserrat {
  font-family: 'Montserrat', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
hr {
  border-style: solid;
  border-width: 2px;
  border-color: #FFFFFF;
}
/* Base styles for the slider */
.relative {
  position: relative;
}

/* For mobile devices */
@media (max-width: 768px) {
  .h-80 {
    height: 100vw; /* Adjust height for mobile devices */
  }
}

/* For tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  .h-80 {
    height: 80vw; /* Adjust height for tablets */
  }
}
/* Sonsuz döngü animasyonu */
@keyframes marquee {
  0% {
    transform: translateX(0); /* Başlangıç noktası */
  }
  100% {
    transform: translateX(-100%); /* Tüm genişliği kaydır */
  }
}

.marquee-container {
  position: relative;
  width: 100%; /* Ekran genişliğinde olacak */
  overflow: hidden; /* Taşan logolar gizlenir */
  white-space: nowrap; /* Tek satırda logoları tut */
}

.animate-marquee {
  display: flex;
  gap: 5rem; /* Logolar arası boşluğu artır */
  width: max-content; /* İçeriğin genişliğini dinamik yap */
  animation: marquee 15s linear infinite; /* Döngü animasyonu */
}

.animate-marquee img {
  height: 4rem; /* Logoların yüksekliği */
  flex-shrink: 0; /* Görsellerin boyutunu küçültmeyi engelle */
}
